<template>
  <div class="page">

    <div class="box1">
      <div></div>
      <img src="../assets/gis/caso.png"
           alt="">
    </div>

    <div class="box2">
      <div class="box3">
        <div class="from">
          <div class="title">
            <p>已有账号?<span @click="login"> &nbsp; 点击登录</span></p>
          </div>
          <div class="list">
            <div @click="goSignUp(item.val)"
                 v-for="item in typeList"
                 :key="item.val">
              <img :src="item.icon"
                   alt="">
            </div>
            <div>
              <img src="#"
                   alt="">
            </div>
            <div>
              <img src="#"
                   alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="panel">
      <div class="header">
        <span class="name">账号注册</span>
        <span class="login">已有账号？<em @click="login">登录</em></span>
      </div>
      <ul class="type">
        <li class="item"
            @click="goSignUp(item.val)"
            v-for="item in typeList"
            :key="item.val">
          <img :src="item.icon"
               alt="" />
        </li>
      </ul>
    </div> -->
  </div>
</template>
<script>
// const student = require("@/assets/user-student.png");
const student = require("@/assets/log-2.png");
// const amateur = require("@/assets/user-amateur.png");
const amateur = require("@/assets/log-1.png");
// const teacher = require("@/assets/user-teacher.png");
const teacher = require("@/assets/log-3.png");
export default {
  name: "SignType",
  data () {
    return {
      typeList: [
        {
          val: 1,
          name: "爱好者",
          icon: amateur,
        },
        {
          val: 2,
          name: "学生用户",
          icon: student,
        },

        {
          val: 3,
          name: "校园用户",
          icon: teacher,
        },
      ],
    };
  },
  methods: {
    login () {
      this.$router.replace("/login");
    },
    goSignUp (type) {
      this.$router.push({ name: "signup", query: { type: type } });
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  .box1 {
    height: 30vh;
    position: relative;
    div {
      width: 100%;
      height: 100%;
      filter: blur(10px);
      background-color: #158bb8;
    }
    img {
      position: absolute;
      width: 30%;
      bottom: 0;
      left: calc(50% - 15%);
    }
  }
  .box2 {
    height: 70vh;
    background-color: #cdd1d3;
    position: relative;
    .box3 {
      background-image: url(../assets/back.png);
      position: absolute;
      top: 0;
      width: 100%;
      height: 70vh;
      display: flex;
      justify-content: center;
      align-items: center;
      .from {
        padding: 30px;
        // background-color: aliceblue;
        // box-shadow: 0px 2px 6px 0px rgba(181, 181, 181, 0.9);
        border-radius: 3px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          p {
            span {
              // color: #158bb8;
              cursor: pointer;
            }
          }
        }
        .list {
          cursor: pointer;
          display: flex;
          justify-content: center;
          > div {
            box-shadow: 0px 2px 6px 0px rgba(181, 181, 181, 0.9);
            margin-top: 50px;
            background-color: #e2eaee;
            > img {
              width: 300px;
            }
          }
          > :nth-child(2) {
            margin-left: 200px;
            margin-right: 200px;
          }
          > div:hover {
            background-color: #50ccf5;
          }
        }

        .title {
          p {
            margin-bottom: 15px;
            font-size: 24px;
            a {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  // width: 100%;
  // height: 100vh;
  // background: url("../assets/image/bg-signup.png") left center no-repeat;
  // background-size: 25% 102%, 290px auto;
  // background-color: #f4f4f4;
  // display: flex;
  // align-items: center;
  // .panel {
  //   margin-left: 35vw;
  //   flex: 1;
  //   background: #fff;
  //   border-radius: 12px;
  //   padding: 100px 59px;
  //   .header {
  //     text-align: center;
  //     position: relative;
  //     top: -40px;
  //     margin-bottom: 80px;
  //     .name {
  //       font-size: 40px;
  //       color: #595959;
  //       font-weight: bold;
  //       position: absolute;
  //       left: 50%;
  //       transform: translateX(-50%);
  //     }
  //     .login {
  //       position: absolute;
  //       line-height: 60px;
  //       font-size: 18px;
  //       font-weight: normal;
  //       transform: translateX(100%);
  //       em {
  //         cursor: pointer;
  //         color: #0160fe;
  //       }
  //     }
  //   }
  //   .type {
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //     .item {
  //       width: 17vw;
  //       cursor: pointer;
  //       img {
  //         max-width: 100%;
  //       }
  //     }
  //   }
  // }
}
</style>